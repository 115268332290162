import React from 'react';
import * as styles from './styles/customLink.module.scss';
import cx from 'classnames';
import { Link } from 'gatsby';

export const CustomLink = ({ 

        children, 
        color = 'inherit', 
        to = "", 
        href = "",
        target = "",
        underline = true, 
        ...props 
    
    }) => {

    // define classes
    const classname = cx(
        styles.customLink,
        styles[`color_${color}`],
        { [`${styles.underline}`]: underline}
    );  


    if(to){

         // render link component
        return (
            <Link to={to} className={classname}>
                {children}
            </Link>
        );

    }else{

        // render button
        return (
            <a rel="noreferrer" href={href} target={target} className={classname}>
                {children}
            </a>
        );


    }


    
};