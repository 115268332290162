import { Link } from 'gatsby';
import React from 'react';
import { Button } from '../../01_atoms/Button/Button';
import { Heading } from '../../01_atoms/Heading/Heading';
import { Paragraph } from '../../01_atoms/Paragraph/Paragraph';
import * as styles from './styles/case.module.scss';
import { Image } from "../../01_atoms/Image/Image";
import Animate from '../Animate/Animate';

function Work({children, ...props}) {
    return (
        <article className={styles.work} data-item-position={props.position}>
            
            <div className={styles.work__visual}>
                <Link to={props.link}>
                    <Animate><Image alt={props.alt} image={props.image} /></Animate>
                </Link>
            </div>

            <div className={styles.work__inner}>
                <Animate>
                    <Heading element="h3" size="l" weight="800" spacing="m">{props.title}</Heading>
                    <Paragraph spacing="l" size="l" uppercase >{props.subtitle}</Paragraph>
                    <Button to={props.link} type="circle">
                        <svg width="17" height="17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.954.837l7.235 7.235-7.235 7.236" stroke="#fff" strokeWidth="2"/></svg>
                    </Button>
                </Animate>
            </div>

            {children}
            
        </article>
    );
}

export default Work;