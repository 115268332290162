import React from 'react';
import * as styles from './styles/navigationLink.module.scss';
import cx from 'classnames';

function NavigationLink({label="navItem", to="#", active="default",theme, ...props}) {

    const classname = cx(
        styles.navlink,
        styles[`theme_${theme}`]
    );  

    function handleClick(e){
        e.preventDefault();
        props.onClick();
    }

    return (
        <a onClick={handleClick} className={classname} href={to}>{label}</a>
    );
}

export default NavigationLink;
