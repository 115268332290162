import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

import { Heading } from "../components/01_atoms/Heading/Heading";
import { Section } from "../components/03_organisms/Section/Section";
import Navigation from "../components/02_molecules/Navigation/Navigation";
import NavigationLink from "../components/01_atoms/NavigationLink/NavigationLink";
import Header from "../components/03_organisms/Header/Header";
import Hero from "../components/03_organisms/Hero/Hero";
import Scrollspy from "react-scrollspy";

import { gsap, ScrollToPlugin } from "gsap/all";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Work from "../components/02_molecules/Work/Work";
import { Paragraph } from "../components/01_atoms/Paragraph/Paragraph";
import Flex from "../components/01_atoms/Flex/Flex";
import { Image } from "../components/01_atoms/Image/Image";
import Contact from "../components/03_organisms/Contact/Contact";
import ScrollIndicator from "../components/01_atoms/ScrollIndicator/ScrollIndicator";

import { HelmetDatoCms } from 'gatsby-source-datocms';
import { graphql } from 'gatsby';
import { CustomLink } from "../components/01_atoms/CustomLink/CustomLink";
import Footer from "../components/03_organisms/Footer/Footer";
import Animate from "../components/02_molecules/Animate/Animate";
import { HeadInject } from "../components/03_organisms/HeadInject/HeadInject";

import { StructuredText } from 'react-datocms';



const Home = ({ data }) => {

	// read language
	const {t} = useTranslation();

	const [theme] = useState(['regular']);

	gsap.registerPlugin(ScrollToPlugin);
	gsap.registerPlugin(ScrollTrigger);

	function scrollToSection(id){
		gsap.to(window, {duration: .75, scrollTo:id});
	}


	useEffect(()=>{

		const tl1 = gsap.timeline({
			scrollTrigger: {
			  start: 'top top',
			  //endTrigger: '#work',
			  end: '+=80%',
			  scrub: 1,
			//   snap: {snapTo: 1, duration: 1, delay: 0},
			//markers: true
			}
		  })

		  tl1.to('#intro__title',{
			  opacity: 0,
			//   yPercent: 200,
			  ease: "none"
		  })
		  tl1.from('#work',{
			opacity: 0,
			ease: "none"
		})

    }, []);


	// render html
	return (
		<div>
			<HeadInject />
			<Header theme={theme} />

			<Navigation>
				<Scrollspy items={ ['work', 'about', 'press', 'contact'] }  currentClassName="is-active" offset={typeof window !== 'undefined' ? -window.innerHeight*.5 : 0} >
					<li className="navlink_parent">
						<NavigationLink onClick={ () => scrollToSection('#work') } label={ t('navigation_item_work') } theme={theme} />
					</li>
					<li className="navlink_parent">
						<NavigationLink onClick={ () => scrollToSection('#about') } label={ t('navigation_item_about') } theme={theme}  />
					</li>
					<li className="navlink_parent">
						<NavigationLink onClick={ () => scrollToSection('#press') } label={ t('navigation_item_press') } theme={theme}  />
					</li>
					<li className="navlink_parent">
						<NavigationLink onClick={ () => scrollToSection('#contact') } label={ t('navigation_item_contact') } theme={theme}  />
					</li>
				</Scrollspy>
			</Navigation>

			<Hero id="intro" intro>
				<div id="intro__title">
					<Flex
						align={['center', 'end']}
						direction={['column', 'row']}
						justify={['end']}
						>
						<Flex.Box w={['1','1-2']} >
							<Flex>
								<Animate delay="2" start="top bottom">
									<ScrollIndicator />
								</Animate>
								<Animate delay="1" start="top bottom">
									<Heading element="h1" size="xl" weight="900"><StructuredText data={data.datoCmsHome.title}></StructuredText></Heading>
								</Animate>
								<HelmetDatoCms seo={data.datoCmsHome.seoMetaTags} />
							</Flex>

						</Flex.Box>
					</Flex>
				</div>

			</Hero>

			<Section fullHeight hasContent id="work"  slanted="bottom">

				<Animate delay="0">
					<Heading element="h2" size="xxl" weight="900" uppercase	 color="blue" spacing="xl" shift >{t('cases_title')}</Heading>
				</Animate>


				{data.allDatoCmsWork.edges.map(({ node: work }) => (

						<Work
							key={work.id}
							position={work.position}
							id={work.id}
							title={work.title}
							subtitle={work.subtitle}
							image={work.headerImage.gatsbyImageData}
							alt={work.headerImage.alt}
							link={work.slug}
						></Work>

				))}

				{/* <Heading size="xl" align="center" spacing="l">
					{t('cases_more_title')}
				</Heading>

				<Paragraph align="center">
					<Button size="s">{t('cases_more_cta')}</Button>
				</Paragraph> */}


			</Section>

			<Section hasContent fullHeight id="about">

				<Flex
					align={['center', 'end']}
					direction={['column', 'row']}
					justify={['space-between']}
					wrap
				>
					<Flex.Box w={['1','1-2']}>
						<Animate>
							<Heading element="h2" size="xxl" weight="900" uppercase	 color="blue" spacing="xl" shift >{data.datoCmsAbout.title}</Heading>
						</Animate>
						<Animate>
							<Heading element="h3" size="xl" weight="900" color="white" spacing="xl"  >{data.datoCmsAbout.subtitle}</Heading>
							</Animate>
					</Flex.Box>
					<Flex.Box w={['1','1-2']} spacing={['','xl']}>
					<Animate>
						<Image image={data.datoCmsAbout.visualTitles.gatsbyImageData} alt={data.datoCmsAbout.visualTitles.alt} />
					</Animate>

					</Flex.Box>
				</Flex>

				<Flex
					align={['center', 'start']}
					direction={['column', 'row-reverse']}
					justify={['space-between']}
					wrap
				>
					<Flex.Box w={['1','1-2']} spacing={['','l','xl']}>
						<Animate>
							<Paragraph spacing="l" color="white">{data.datoCmsAbout.bodyText}</Paragraph>
							</Animate>
					</Flex.Box>
					<Flex.Box w={['1','1-2']} spacing={['','l','l']}>
						<Animate>
							<Image image={data.datoCmsAbout.visualBody.gatsbyImageData} alt={data.datoCmsAbout.visualBody.alt} />
						</Animate>
					</Flex.Box>
				</Flex>

				<Flex
					align={['center', 'center']}
					direction={['column', 'row']}
					justify={['space-between']}
					wrap
				>
					<Flex.Box w={['1','1-2']} spacing={['','l','xl']}>
						<Animate>
							<Heading element="h3" size="xl" weight="900" color="white" spacing="xl"  >{data.datoCmsBuilding.title}</Heading>
							<Paragraph spacing="l" color="white">{data.datoCmsBuilding.bodyText}</Paragraph>
							</Animate>
					</Flex.Box>
					<Flex.Box w={['1','1-2']} spacing={['','l','l']}>
						<Animate>
							<Image image={data.datoCmsBuilding.visual.gatsbyImageData} alt={data.datoCmsBuilding.visual.alt} />
						</Animate>
					</Flex.Box>
				</Flex>





			</Section>

			<Section hasContent fullHeight id="press">
				<Animate>
					<Heading element="h2" size="xxl" weight="900" uppercase	 color="blue" spacing="xl" shift >{t('press_title')}</Heading>
				</Animate>
				<Animate>
					<Heading element="h3" size="xl" weight="900" color="white" spacing="xl"  >{t('press_subtitle')} <CustomLink target="_blank" href={t('press_link')} color="blue" underline>{t('press_link_caption')}</CustomLink></Heading>
				</Animate>
			</Section>



			<Contact />
			<Footer />



		</div>
	)
}

export default Home;

export const query = graphql`
	query MyQuery {
		datoCmsHome {
			title{
				value
			}
			seoMetaTags {
				...GatsbyDatoCmsSeoMetaTags
			}
		}
		datoCmsAbout{
			title
			visualBody {
				gatsbyImageData
				alt
			}
			visualTitles {
				gatsbyImageData
				alt
			}
			subtitle
			bodyText
		}
		datoCmsBuilding{
			title
			visual {
				gatsbyImageData
				alt
			}
			bodyText
		}
	    allDatoCmsWork(sort: {fields: position, order: ASC}) {
			edges {
				node {
				  id
				  position
				  title
				  subtitle
				  slug
				  headerImage {
					gatsbyImageData
					alt
				  }
				  seoMetaTags {
					...GatsbyDatoCmsSeoMetaTags
				  }
				}
			  }
		}
	}
  
`
