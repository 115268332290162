import React from 'react';
import * as styles from './styles/navigation.module.scss';

function Navigation({children,...props}) {

    return (
        <nav className={styles.navigation}>
            {children}
        </nav>
    );
}

export default Navigation;
